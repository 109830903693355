<template>
  <div>
    <v-row class="match-height">
      <!-- <v-col
        cols="12"
        md="6"
        lg="4"
      >

      </v-col>
      <v-col
        cols="12"
        md="6"
        lg="4"
      >
      </v-col> -->
      <v-col
        cols="12"
        md="5"
      >
        <v-row>
          <v-col
            cols="12"
            xl="12"
          >
            <welcome-card />
          </v-col>
          <v-col
            cols="12"
            xl="12"
          >
            <integration-card />
          </v-col>
        </v-row>
      </v-col>
      <v-col
        cols="12"
        md="7"
      >
        <assignment-card />
      </v-col>
    </v-row>
    <v-row class="match-height">
      <v-col
        cols="12"
        md="6"
      >
        <v-card
          class="pa-4 d-flex flex-column align-start slide-height"
          min-height="200px"
        >
          <span class="text-subtitle-1 primary--text font-medium d-block">
            Rekomendasi Produk
          </span>
          <span class="text-subtitle-2 mt-2">
            Sistem rekomendasi produk adalah alat utama untuk memberikan
            saran yang akurat dan personal kepada pelanggan. Temukan bagaimana
            sistem ini dapat meningkatkan kepuasan pelanggan dan mendorong penjualan.
          </span>
          <router-link
            :to="{ name: 'products' }"
            class="mt-auto"
          >
            <v-btn
              color="primary"
              outlined
              class="mt-auto"
              small
              text
            >
              Coba Sekarang
            </v-btn>
          </router-link>
        </v-card>
      </v-col>
      <v-col
        cols="12"
        md="6"
      >
        <v-card min-height="200px">
          <v-card
            class="pa-4 d-flex flex-column align-start slide-height"
            min-height="200px"
          >
            <span class="text-subtitle-1 primary--text font-medium d-block">
              Manajemen Tugas
            </span>
            <span class="text-subtitle-2 mt-2">
              Manajemen tugas penjualan adalah kunci untuk meningkatkan produktivitas
              dan mencapai tujuan penjualan. Temukan strategi dan alat yang efektif untuk
              mengelola tugas penjualan secara efisien guna mendorong kesuksesan dalam tim penjualan Anda.
            </span>
            <router-link
              :to="{ name: 'folder-detail', params: { id: state.folderList.value[0].id } }"
              class="mt-auto"
            >
              <v-btn
                color="primary"
                outlined
                :loading="state.loadingFolder.value"
                class="mt-auto"
                small
                text
              >
                Mulai Sekarang
              </v-btn>
            </router-link>
          </v-card>
        </v-card>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import WelcomeCard from './WelcomeCard.vue'
// import AssignmentCard from './AssignmentCard.vue'
import AssignmentCard from './AssignmentCard.vue'
import IntegrationCard from './IntegrationCard.vue'
// import ClassicEditor from '@ckeditor/ckeditor5-editor-classic/src/classiceditor'
// import Mention from '@ckeditor/ckeditor5-mention/src/mention'
import { ref } from '@vue/composition-api'
import { createFieldMapper } from 'vuex-use-fields'

const useFieldFolder = createFieldMapper({ getter: 'folder/getField', setter: 'folder/setField' })

export default {
  components: {
    WelcomeCard,
    AssignmentCard,
    IntegrationCard,
  },
  setup() {
    const state = {
      ...useFieldFolder([
        'loadingFolder', 'folderList', 'filter',
      ]),
    }
    const editorData = ref('')
    // const editorConfig = ref({
    //   plugins: [Mention],
    //   toolbar: ['heading', '|', 'bold', 'italic', 'link', 'bulletedList', 'numberedList', 'blockQuote'],
    //   mention: {
    //     feeds: [
    //       {
    //         marker: '@',
    //         feed: ['Anna', 'Thomas', 'John', 'Jane', 'Amy', 'Adam', 'Anne', 'Alice'],
    //         minimumCharacters: 0,
    //       },
    //     ],
    //   },
    // })

    return {
      // ClassicEditor,
      editorData,
      state,
      // editorConfig,
    }
  },
}
</script>

<style lang="scss">

</style>
