import { VAvatar } from 'vuetify/lib/components/VAvatar';
import { VCard } from 'vuetify/lib/components/VCard';
import { VChip } from 'vuetify/lib/components/VChip';
import { VTooltip } from 'vuetify/lib/components/VTooltip';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VCard,{staticClass:"pa-2 my-2 cursor-pointer",attrs:{"min-height":"125px","flat":"","outlined":""},on:{"click":function($event){$event.stopPropagation();return _vm.objectDetail()}}},[_c('div',{staticClass:"d-flex justify-space-between"},[_c('div',{staticClass:"d-flex align-start flex-grow-0 task-detail-left justify-space-between"},[_c('div',{staticClass:"d-flex flex-column"},[_c('div',{staticClass:"d-flex flex-wrap"},[_c('h5',{staticClass:"font-large mb-1 mr-2"},[_vm._v(" "+_vm._s(_vm.title)+" ")]),_c('LimitElements',{attrs:{"elements":_vm.item.tag,"limit":3},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var data = ref.data;
return [_c(VChip,{staticClass:"mr-1",attrs:{"x-small":"","color":data.color}},[_vm._v(" "+_vm._s(data.name)+" ")])]}},{key:"others-activator",fn:function(ref){
var data = ref.data;
return [_c(VChip,{staticClass:"mr-1 v-chip-light-bg primary--text mb-2",attrs:{"x-small":"","color":"primary"}},[_vm._v(" +"+_vm._s(data.limit)+" ")])]}}])})],1),_c('span',{staticClass:"text-caption mr-2"},[_vm._v(" "+_vm._s(_vm.item.description)+" ")])])]),_c('div',{staticClass:"d-flex flex-column text-caption text-md-right font-weight-bold align-end"},[(_vm.type === 'Job')?_c('div',[_c(VTooltip,{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c(VChip,_vm._g(_vm._b({attrs:{"label":"","small":"","color":_vm.priorityColor(_vm.item.priority.id)}},'v-chip',attrs,false),on),[_vm._v(" "+_vm._s(_vm.item.priority.name)+" ")])]}}],null,false,4245431604)},[_c('span',[_vm._v(" Prioritas job ")])])],1):_vm._e(),(_vm.type === 'Prospect')?_c('div',[_c(VTooltip,{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('chip-display',_vm._g(_vm._b({attrs:{"status":_vm.item.status}},'chip-display',attrs,false),on))]}}],null,false,3280701216)},[_c('span',[_vm._v(" Prioritas job ")])])],1):_vm._e(),_c(VTooltip,{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('span',_vm._g(_vm._b({staticClass:"text-caption text-md-right mt-2"},'span',attrs,false),on),[_vm._v(" "+_vm._s(_vm.deadlineDate ? _vm.dateFormat(_vm.deadlineDate, _vm.dateType) : '-')+" ")])]}}])},[_c('div',{staticClass:"d-flex flex-column"},[_c('span',[_vm._v("Dibuat: "+_vm._s(_vm.dateFormat(_vm.item.created_at, 3)))]),_c('span',[_vm._v("Diperbarui: "+_vm._s(_vm.dateFormat(_vm.item.updated_at, 3)))])])])],1)]),_c('div',{staticClass:"d-flex flex-wrap justify-space-between align-end"},[_c(VTooltip,{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [((_vm.item.customer && !_vm.isJobProspectTask))?_c('div',_vm._g(_vm._b({staticClass:"d-sm-flex align-center d-none cursor-pointer",on:{"click":function($event){$event.stopPropagation();return _vm.$router.push({
            name: 'customer-detail',
            params: { id: _vm.item.customer.id }
          })}}},'div',attrs,false),on),[_c(VAvatar,{staticClass:"v-avatar-light-bg primary--text mr-2",attrs:{"color":"primary","size":"32"}},[_vm._v(" "+_vm._s(_vm.avatarText(_vm.item.customer.name))+" ")]),_c('div',{staticClass:"d-flex flex-column"},[_c('span',{staticClass:"text-caption primary--text font-medium"},[_vm._v(_vm._s(_vm.item.customer.name))]),_c('span',{staticClass:"text-caption text--disabled"},[_vm._v(_vm._s(_vm.item.customer.phone_number))])])],1):_vm._e()]}}])},[_c('span',[_vm._v("Nama Pelanggan")])])],1)])}
var staticRenderFns = []

export { render, staticRenderFns }