<template>
  <div>
    <v-navigation-drawer
      height="100%"
      width="230"
      touchless
      mobile-breakpoint="sm"
      permanent
      absolute
      class="left-sidebar"
      :mini-variant="!isLeftSidebarOpen"
    >
      <div class="item-list">
        <v-list
          width="240px"
        >
          <v-list-item-group
            v-model="selectedType"
            color="primary"
            mandatory
          >
            <v-list-item
              v-for="(item, i) in typeList"
              :key="i"
              class="ps-2"
            >
              <v-list-item-icon
                class="ma-0 align-self-center"
              >
                <v-badge
                  :color="item.color"
                  dot
                  inline
                  class="align-self-center label-dot"
                />
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title>
                  <v-badge
                    color="error"
                    inline
                    class="ma-0"
                    :value="count[i]"
                    :content="count[i]"
                  >
                    {{ item.name }}
                  </v-badge>
                </v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-list-item-group>
        </v-list>
      </div>
    </v-navigation-drawer>
    <div
      v-scroll.self="listenScrollDashboardAssignment"
      :style="{
        'margin-left': isLeftSidebarOpen && $vuetify.breakpoint.smAndUp ? '230px' : '50px',
        'height': '586px',
      }"
      class="list px-2 pt-1 pb-0"
    >
      <v-tabs-items
        v-model="selectedType"
        touchless
      >
        <v-tab-item

          class="h-100"
        >
          <v-skeleton-loader
            v-if="loadingFetch"
            type="table-heading, list-item-three-line, divider, table-heading, list-item-three-line, divider, table-heading, list-item-three-line, divider"
          />
          <div
            v-else-if="(list && list.new[identifier].length > 0)"
          >
            <assignment-item
              v-for="item in list.new[identifier]"
              :key="item.id"
              :item="item"
              :type="name"
            />
          </div>
          <div
            v-else
            class="d-flex justify-center align-center"
            style="height: 450px"
          >
            <span>Tidak ada data</span>
          </div>
        </v-tab-item>

        <v-tab-item
          class="h-100"
        >
          <v-skeleton-loader
            v-if="loadingFetch"
            type="table-heading, list-item-three-line, divider, table-heading, list-item-three-line, divider, table-heading, list-item-three-line, divider"
          />
          <div
            v-else-if="(list && list.today[identifier].length > 0)"
          >
            <assignment-item
              v-for="item in list.today[identifier]"
              :key="item.id"
              :item="item"
              :type="name"
            />
          </div>
          <div
            v-else
            class="d-flex justify-center align-center"
            style="height: 450px"
          >
            <span>Tidak ada data</span>
          </div>
        </v-tab-item>

        <v-tab-item
          class="h-100"
        >
          <v-skeleton-loader
            v-if="loadingFetch"
            type="table-heading, list-item-three-line, divider, table-heading, list-item-three-line, divider, table-heading, list-item-three-line, divider"
          />
          <div
            v-else-if="(list && list.tomorrow[identifier].length > 0)"
          >
            <assignment-item
              v-for="item in list.tomorrow[identifier]"
              :key="item.id"
              :item="item"
              :type="name"
            />
          </div>
          <div
            v-else
            class="d-flex justify-center align-center"
            style="height: 450px"
          >
            <span>Tidak ada data</span>
          </div>
        </v-tab-item>

        <v-tab-item
          class="h-100"
        >
          <v-skeleton-loader
            v-if="loadingFetch"
            type="table-heading, list-item-three-line, divider, table-heading, list-item-three-line, divider, table-heading, list-item-three-line, divider"
          />
          <div
            v-else-if="(list && list.late[identifier].length > 0)"
          >
            <assignment-item
              v-for="item in list.late[identifier]"
              :key="item.id"
              :item="item"
              :type="name"
            />
          </div>
          <div
            v-else
            class="d-flex justify-center align-center"
            style="height: 450px"
          >
            <span>Tidak ada data</span>
          </div>
        </v-tab-item>

        <v-tab-item
          class="h-100"
        >
          <v-skeleton-loader
            v-if="loadingFetch"
            type="table-heading, list-item-three-line, divider, table-heading, list-item-three-line, divider, table-heading, list-item-three-line, divider"
          />
          <div
            v-else-if="(list && list.unscheduled[identifier].length > 0)"
          >
            <assignment-item
              v-for="item in list.unscheduled[identifier]"
              :key="item.id"
              :item="item"
              :type="name"
            />
          </div>
          <div
            v-else
            class="d-flex justify-center align-center"
            style="height: 450px"
          >
            <span>Tidak ada data</span>
          </div>
        </v-tab-item>
      </v-tabs-items>
    </div>
  </div>
</template>
<script>
import { ref, watchEffect, computed } from '@vue/composition-api'
import AssignmentItem from './AssignmentItem.vue'
import { mdiMenu } from '@mdi/js'
import { getVuetify } from '@/@core/utils'
import { createFieldMapper } from 'vuex-use-fields'
import { useDebounceFn } from '@vueuse/core'
import useDashboard from '@/composables/useDashboard'

const useFieldDashboard = createFieldMapper({ getter: 'dashboard/getField', setter: 'dashboard/setField' })

export default {
  components: {
    // eslint-disable-next-line vue/no-unused-components
    AssignmentItem,
  },
  props: {
    data: {
      type: Object,
      default: () => {},
    },
    name: {
      type: String,
      required: true,
    },
    count: {
      type: Array,
      default: () => [0, 0, 0, 0, 0],
    },
  },
  setup(props) {
    const state = {
      ...useFieldDashboard([
        'tasks', 'jobs', 'prospects',
        'loadingTasks', 'loadingJobs', 'loadingProspects',
        'taskPagination', 'jobPagination', 'prospectPagination',
      ]),
    }

    const { fetchDashboardTasks } = useDashboard({ pagination: state.taskPagination.value })
    const { fetchDashboardJobs } = useDashboard({ pagination: state.jobPagination.value })
    const { fetchDashboardProspects } = useDashboard({ pagination: state.prospectPagination.value })

    const loadingFetch = computed(() => {
      if (props.name === 'Task') return state.loadingTasks.value
      if (props.name === 'Job') return state.loadingJobs.value
      if (props.name === 'Prospect') return state.loadingProspects.value

      return true
    })

    const list = computed(() => {
      if (props.name === 'Task') return state.tasks.value
      if (props.name === 'Job') return state.jobs.value
      if (props.name === 'Prospect') return state.prospects.value

      return false
    })

    const identifier = computed(() => {
      if (props.name === 'Task') return 'tasks'
      if (props.name === 'Job') return 'jobs'
      if (props.name === 'Prospect') return 'prospects'

      return false
    })

    const selectedType = ref(null)
    const $vuetify = getVuetify()
    const typeList = [
      { name: `New ${props.name}`, color: 'primary' },
      { name: `Due ${props.name}`, color: 'warning' },
      { name: `Tomorrow ${props.name}`, color: 'success' },
      { name: `Overdue ${props.name}`, color: 'error' },
      { name: `Unscheduled ${props.name}`, color: 'secondary' },
    ]
    const isLeftSidebarOpen = ref(true)

    watchEffect(() => {
      if ($vuetify.breakpoint.smAndUp) isLeftSidebarOpen.value = true
      else isLeftSidebarOpen.value = false
    })

    const listenScrollDashboardAssignment = useDebounceFn(({ target }) => {
      console.log(props.name)
      if (target.offsetHeight + target.scrollTop >= target.scrollHeight * 0.90) {
        if (props.name === 'Task') {
          state.taskPagination.value.offset += state.taskPagination.value.limit
          fetchDashboardTasks().then(result => {
            state.tasks.value.new.tasks = [...state.tasks.value.new.tasks, ...result.new.tasks]
            state.tasks.value.today.tasks = [...state.tasks.value.today.tasks, ...result.today.tasks]
            state.tasks.value.late.tasks = [...state.tasks.value.late.tasks, ...result.late.tasks]
          })
        } else if (props.name === 'Job') {
          state.jobPagination.value.offset += state.jobPagination.value.limit
          fetchDashboardJobs().then(result => {
            state.jobs.value.new.jobs = [...state.jobs.value.new.jobs, ...result.new.jobs]
            state.jobs.value.today.jobs = [...state.jobs.value.today.jobs, ...result.today.jobs]
            state.jobs.value.late.jobs = [...state.jobs.value.late.jobs, ...result.late.jobs]
          })
        } else if (props.name === 'Prospect') {
          state.prospectPagination.value.offset += state.prospectPagination.value.limit
          fetchDashboardProspects().then(result => {
            state.prospects.value.new.prospects = [...state.prospects.value.new.prospects, ...result.new.prospects]
            state.prospects.value.today.prospects = [...state.prospects.value.today.prospects, ...result.today.prospects]
            state.prospects.value.late.prospects = [...state.prospects.value.late.prospects, ...result.late.prospects]
          })
        }
      }
    }, 750)

    return {
      selectedType,
      typeList,
      isLeftSidebarOpen,
      icons: {
        mdiMenu,
      },
      state,
      loadingFetch,
      list,
      identifier,
      listenScrollDashboardAssignment,
    }
  },
}
</script>
<style lang="scss">
@import '~@core/preset/preset/mixins.scss';
@import '~vuetify/src/styles/styles.sass';

.item-list {
  width: 280px;
  border-radius: 6px;
}

.app-config {
  width: 100%;
  border-radius: 0 6px 6px 0;
}

.list {
  @include style-scroll-bar();
  padding-bottom: 16px;
  overflow-y: scroll;
  &::-webkit-scrollbar {
    background: none;
    padding-top: 16px;
  }
}
</style>
