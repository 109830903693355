import { dashboardProspects, dashboardTasks, dashboardJobs } from '@/graphql/queries'
// import dashboardJobs from '@/graphql/query/dashboardJobs'
import { apolloClient } from '@/vue-apollo'
import { ref } from '@vue/composition-api'

const useDashboard = ({
  pagination,
}) => {
  const loadingTasks = ref(false)
  const loadingJobs = ref(false)
  const loadingProspects = ref(false)

  const fetchDashboardTasks = async () => {
    loadingTasks.value = true

    return new Promise((resolve, reject) => {
      apolloClient.query({
        query: dashboardTasks,
        variables: {
          pagination,
        },
        fetchPolicy: 'no-cache',
      }).then(({ data }) => {
        loadingTasks.value = false
        resolve(data.dashboardTasks)
      }).catch(err => {
        loadingTasks.value = false
        reject(err)
      })
    })
  }

  const fetchDashboardJobs = async () => {
    loadingJobs.value = true

    return new Promise((resolve, reject) => {
      apolloClient.query({
        query: dashboardJobs,
        variables: {
          pagination,
        },
        fetchPolicy: 'no-cache',
      }).then(({ data }) => {
        loadingJobs.value = false
        resolve(data.dashboardJobs)
      }).catch(err => {
        loadingJobs.value = false
        reject(err)
      })
    })
  }

  const fetchDashboardProspects = async () => {
    loadingProspects.value = true

    return new Promise((resolve, reject) => {
      apolloClient.query({
        query: dashboardProspects,
        variables: {
          pagination,
        },
        fetchPolicy: 'no-cache',
      }).then(({ data }) => {
        loadingProspects.value = false
        resolve(data.dashboardProspects)
      }).catch(err => {
        loadingProspects.value = false
        reject(err)
      })
    })
  }

  return {
    fetchDashboardTasks,
    fetchDashboardJobs,
    fetchDashboardProspects,
  }
}

export default useDashboard
