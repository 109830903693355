<template>
  <v-card
    min-height="200px"
    max-height="640px"
  >
    <v-tabs
      v-model="state.currentTabAssignment.value"
      :show-arrows="true"
      grow
      centered
      mobile-breakpoint="md"
      height="56px"
    >
      <v-tab
        @click="fetchJob"
      >
        <v-badge
          color="error"
          :value="totalJob"
          :content="totalJob"
        >
          Job
        </v-badge>
      </v-tab>
      <v-tab
        @click="fetchTask"
      >
        <v-badge
          color="error"
          :value="totalTask"
          :content="totalTask"
        >
          Task
        </v-badge>
      </v-tab>
      <!-- <v-tab
        @click="fetchProspect"
      >
        <v-badge
          color="error"
          :value="totalProspect"
          :content="totalProspect"
        >
          Prospect
        </v-badge>
      </v-tab> -->
    </v-tabs>
    <v-tabs-items
      v-model="state.currentTabAssignment.value"
    >
      <v-tab-item>
        <assignment-list
          name="Job"
          :data="state.jobs.value"
          :count="state.jobCount.value"
        />
      </v-tab-item>
      <v-tab-item>
        <assignment-list
          name="Task"
          :data="state.tasks.value"
          :count="state.taskCount.value"
        />
      </v-tab-item>
      <!-- <v-tab-item>
        <assignment-list
          name="Prospect"
          :data="state.prospects.value"
          :count="state.prospectCount.value"
        />
      </v-tab-item> -->
    </v-tabs-items>
  </v-card>
</template>
<script>
import AssignmentList from './AssignmentList.vue'
import { computed, onMounted } from '@vue/composition-api'
import { createFieldMapper } from 'vuex-use-fields'
import useDashboard from '@/composables/useDashboard'

const useFieldDashboard = createFieldMapper({ getter: 'dashboard/getField', setter: 'dashboard/setField' })

export default {
  components: {
    AssignmentList,
  },
  setup() {
    const state = {
      ...useFieldDashboard([
        'tasks', 'jobs', 'prospects',
        'taskCount', 'jobCount', 'prospectCount',
        'loadingTasks', 'loadingJobs', 'loadingProspects',
        'taskPagination', 'jobPagination', 'prospectPagination',
        'currentTabAssignment',
      ]),
    }

    const totalTask = computed(() => state.taskCount.value.reduce((a, b) => a + b, 0))
    const totalJob = computed(() => state.jobCount.value.reduce((a, b) => a + b, 0))
    const totalProspect = computed(() => state.prospectCount.value.reduce((a, b) => a + b, 0))

    const {
      fetchDashboardTasks,
      fetchDashboardJobs,
      // fetchDashboardProspects
    } = useDashboard({ pagination: { limit: 5, offset: 0 } })
    const fetchTask = async () => {
      if (!state.tasks.value) {
        state.loadingTasks.value = true
        fetchDashboardTasks().then(res => {
          state.tasks.value = res
          state.taskCount.value = [res.new.count, res.today.count, res.tomorrow.count, res.late.count, res.unscheduled.count]
          console.log(state.tasks.value)
          state.loadingTasks.value = false
        }).catch(() => {
          state.loadingTasks.value = false
        })
      }
    }

    const fetchJob = async () => {
      if (!state.jobs.value) {
        state.loadingJobs.value = true
        fetchDashboardJobs().then(res => {
          state.jobs.value = res
          state.jobCount.value = [res.new.count, res.today.count, res.tomorrow.count, res.late.count, res.unscheduled.count]
          state.loadingJobs.value = false
        }).catch(() => {
          state.loadingJobs.value = false
        })
      }
    }

    // const fetchProspect = async () => {
    //   if (!state.prospects.value) {
    //     state.loadingProspects.value = true
    //     fetchDashboardProspects().then(res => {
    //       console.log(res)
    //       state.prospects.value = res
    //       state.prospectCount.value = [res.new.count, res.today.count, res.tomorrow.count, res.late.count, res.unscheduled.count]
    //       state.loadingProspects.value = false
    //     }).catch(() => {
    //       state.loadingProspects.value = false
    //     })
    //   }
    // }

    onMounted(() => {
      fetchTask()
      fetchJob()
      // fetchProspect()
    })

    return {
      fetchTask,
      fetchJob,
      // fetchProspect,
      state,
      totalTask,
      totalJob,
      totalProspect,
    }
  },
}
</script>
