<template>
  <v-card
    min-height="125px"
    flat
    outlined
    class="pa-2 my-2 cursor-pointer"
    @click.stop="objectDetail()"
  >
    <div class="d-flex justify-space-between">
      <div
        class="d-flex align-start flex-grow-0 task-detail-left justify-space-between"
      >
        <div class="d-flex flex-column">
          <div class="d-flex flex-wrap">
            <h5 class="font-large mb-1 mr-2">
              {{ title }}
            </h5>
            <LimitElements
              :elements="item.tag"
              :limit="3"
            >
              <template #default="{ data }">
                <v-chip
                  x-small
                  :color="data.color"
                  class="mr-1"
                >
                  {{ data.name }}
                </v-chip>
              </template>
              <template #others-activator="{ data }">
                <v-chip
                  x-small
                  color="primary"
                  class="mr-1 v-chip-light-bg primary--text mb-2"
                >
                  +{{ data.limit }}
                </v-chip>
              </template>
            </LimitElements>
          </div>
          <span class="text-caption mr-2">
            {{ item.description }}
          </span>
        </div>
      </div>
      <div
        class="d-flex flex-column text-caption text-md-right font-weight-bold align-end"
      >
        <!-- <div class="mb-2">
          <v-tooltip top>
            <template v-slot:activator="{ on, attrs }">
              <v-chip
                label
                outlined
                small
                color="primary"
                v-bind="attrs"
                v-on="on"
              >
                {{ item.workspace.name }}
              </v-chip>
            </template>
            <span>
              Nama Workspace
            </span>
          </v-tooltip>
        </div> -->
        <div v-if="type === 'Job'">
          <v-tooltip top>
            <template v-slot:activator="{ on, attrs }">
              <v-chip
                label
                small
                :color="priorityColor(item.priority.id)"
                v-bind="attrs"
                v-on="on"
              >
                {{ item.priority.name }}
              </v-chip>
            </template>
            <span>
              Prioritas job
            </span>
          </v-tooltip>
        </div>
        <div v-if="type === 'Prospect'">
          <v-tooltip top>
            <template v-slot:activator="{ on, attrs }">
              <chip-display
                v-bind="attrs"
                :status="item.status"
                v-on="on"
              />
            </template>
            <span>
              Prioritas job
            </span>
          </v-tooltip>
        </div>
        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <span
              class="text-caption text-md-right mt-2"
              v-bind="attrs"
              v-on="on"
            >
              {{ deadlineDate ? dateFormat(deadlineDate, dateType) : '-' }}
            </span>
          </template>
          <div class="d-flex flex-column">
            <span>Dibuat: {{ dateFormat(item.created_at, 3) }}</span>
            <span>Diperbarui: {{ dateFormat(item.updated_at, 3) }}</span>
          </div>
        </v-tooltip>
      </div>
    </div>
    <div class="d-flex flex-wrap justify-space-between align-end">
      <v-tooltip top>
        <template v-slot:activator="{ on, attrs }">
          <div
            v-if="(item.customer && !isJobProspectTask)"
            v-bind="attrs"
            class="d-sm-flex align-center d-none cursor-pointer"
            v-on="on"
            @click.stop="$router.push({
              name: 'customer-detail',
              params: { id: item.customer.id }
            })"
          >
            <v-avatar
              color="primary"
              class="v-avatar-light-bg primary--text mr-2"
              size="32"
            >
              {{ avatarText(item.customer.name) }}
            </v-avatar>
            <div class="d-flex flex-column">
              <span class="text-caption primary--text font-medium">{{ item.customer.name }}</span>
              <span class="text-caption text--disabled">{{ item.customer.phone_number }}</span>
            </div>
          </div>
        </template>
        <span>Nama Pelanggan</span>
      </v-tooltip>

      <!-- <div class="d-flex flex-column ml-auto align-end">
        <div>
          <v-tooltip
            bottom
            allow-overflow
          >
            <template v-slot:activator="{ on, attrs }">
              <v-icon
                size="20"
                class="text--disabled"
                v-bind="attrs"
                v-on="on"
              >
                {{ resolveIconVisibility(item.visibility.id) }}
              </v-icon>
            </template>
            <span class="d-block">Visibilitas: {{ item.visibility.name }}</span>
            <span>{{ item.visibility.description }}</span>
          </v-tooltip>
        </div>
      </div> -->
    </div>
  </v-card>
</template>
<script>
import useVisibility from '@/composables/useVisibility'
import dateFormat from '@/utils/dateFormat'
import LimitElements from '@/components/misc/LimitElements.vue'
import { avatarText } from '@core/utils/filter'
import { computed } from '@vue/composition-api'
import useJob from '@/composables/useJob'
import { getVuetify, useRouter } from '@/@core/utils'
import ChipDisplay from '@/views/prospect/component/ChipDisplay.vue'

export default {
  components: {
    LimitElements,
    ChipDisplay,
  },
  props: {
    isJobProspectTask: {
      type: Boolean,
      default: false,
    },
    item: {
      type: Object,
      required: true,
    },
    type: {
      type: String,
      required: true,
    },
  },
  setup(props) {
    const { router } = useRouter()
    const { priorityColor } = useJob()
    const { resolveIconVisibility } = useVisibility()
    const $vuetify = getVuetify()
    const title = computed(() => {
      console.log(props.item)
      if (props.type === 'Task') return props.item.subject
      if (props.type === 'Job') return props.item.name
      if (props.type === 'Prospect') return props.item.name

      return ''
    })

    const deadlineDate = computed(() => {
      if (props.type === 'Task') return props.item.end_date
      if (props.type === 'Job') return props.item.expected_close_date
      if (props.type === 'Prospect') return props.item.expired_at

      return ''
    })

    const dateType = computed(() => {
      if (props.type === 'Task') return $vuetify.breakpoint.smAndDown ? 3 : 1
      if (props.type === 'Job') return $vuetify.breakpoint.smAndDown ? 3 : 1
      if (props.type === 'Prospect') return 4

      return 1
    })

    const objectDetail = () => {
      if (props.type === 'Task') {
        router.push({
          name: 'activities',
          params: { id: props.item.id, workspace: props.item.workspace.identifier_id },
        })
      }
      if (props.type === 'Job') {
        router.push({
          name: 'job-detail',
          params: { id: props.item.id, workspace: props.item.workspace.identifier_id },
        })
      }
      if (props.type === 'Prospect') {
        router.push({
          name: 'prospect-detail',
          params: { id: props.item.id, workspace: props.item.workspace.identifier_id },
        })
      }

      return {}
    }

    return {
      dateFormat,
      resolveIconVisibility,
      avatarText,
      title,
      deadlineDate,
      priorityColor,
      dateType,
      objectDetail,
    }
  },
}
</script>
