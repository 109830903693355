import { VBadge } from 'vuetify/lib/components/VBadge';
import { VCard } from 'vuetify/lib/components/VCard';
import { VTab } from 'vuetify/lib/components/VTabs';
import { VTabItem } from 'vuetify/lib/components/VTabs';
import { VTabs } from 'vuetify/lib/components/VTabs';
import { VTabsItems } from 'vuetify/lib/components/VTabs';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VCard,{attrs:{"min-height":"200px","max-height":"640px"}},[_c(VTabs,{attrs:{"show-arrows":true,"grow":"","centered":"","mobile-breakpoint":"md","height":"56px"},model:{value:(_vm.state.currentTabAssignment.value),callback:function ($$v) {_vm.$set(_vm.state.currentTabAssignment, "value", $$v)},expression:"state.currentTabAssignment.value"}},[_c(VTab,{on:{"click":_vm.fetchJob}},[_c(VBadge,{attrs:{"color":"error","value":_vm.totalJob,"content":_vm.totalJob}},[_vm._v(" Job ")])],1),_c(VTab,{on:{"click":_vm.fetchTask}},[_c(VBadge,{attrs:{"color":"error","value":_vm.totalTask,"content":_vm.totalTask}},[_vm._v(" Task ")])],1)],1),_c(VTabsItems,{model:{value:(_vm.state.currentTabAssignment.value),callback:function ($$v) {_vm.$set(_vm.state.currentTabAssignment, "value", $$v)},expression:"state.currentTabAssignment.value"}},[_c(VTabItem,[_c('assignment-list',{attrs:{"name":"Job","data":_vm.state.jobs.value,"count":_vm.state.jobCount.value}})],1),_c(VTabItem,[_c('assignment-list',{attrs:{"name":"Task","data":_vm.state.tasks.value,"count":_vm.state.taskCount.value}})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }